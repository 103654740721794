import { ChangeEventHandler, useRef, useState } from "react"
import styles from "./Date.module.css"


interface DateProps {
    id: string
    name: string
    value: string
    min: string
    max: string
    dataError?: boolean
    ref: React.RefObject<HTMLInputElement>,
    onChange: ChangeEventHandler<HTMLInputElement>
}



const Date = (props: DateProps) => {

    return (

        <div
            className={styles.container_input}
        >
            <input
                className={props.dataError ? styles.dataInputError : styles.dataInput}
                type="date"
                id={props.id}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                ref={props.ref}>
            </input>
        </div>

    )

}

export default Date