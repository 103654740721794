import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react';
import styles from './Machine.module.css';
import Button from '@/components/UI/Button';
import { useTranslation } from 'react-i18next';
import Box from '@/components/Box';
import Img from '@/components/UI/Img';
import StandardMachineIcon from '../../assets/images/standardMachine.svg';
import deleteIcon from '../../assets/images/delete.svg';
import editIcon from '../../assets/images/edit.svg';
import plusIcon from '../../assets/images/plus.svg';
import downloadIcon from '../../assets/images/download.svg';
import notification_bell from '@Images/NotificationBell_blue.svg';

import Maps from '@/components/UI/Maps';
import InputField from '@/components/UI/InputField';
import Label from '@/components/UI/Label';
import FilterBar from '@/components/Filter/Filterbar';
import Table from '@/components/UI/Table/Table';
import { useRemoteGet, useWindowContext } from '@/hooks';
import {
	BenchmarkDTO,
	ContractDTO,
	CustomBenchmarkResourceService,
	CustomContractResourceService,
	CustomDeviceDetailResourceService,
	CustomDeviceResourceService,
	CustomMetadataResourceService,
	CustomPcCatalogueResourceService,
	DeviceDTO
} from '@/services/openapi';
import MainContext from '@/contexts/MainContext';
import moment from 'moment-timezone';
import MachineDetailNotification from '@/components/MachineDetailNotification';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { PagingContextProvider } from '@/contexts/providers/PagingContextProvider';
import { useRemotePost } from '@/hooks/useRemote/useRemotePost';
import type = BenchmarkDTO.type;
import DownloadButton from '@/components/UI/DownloadButton';
import { formDateToTimestamp } from '@/utils';
import { useFilterDateContext } from '@/hooks/FilterDateContext/useFilterDateContext';
import Collapse from '@/components/UI/Collapse';
import CustomLoader from '@/components/UI/CustomLoader';
import AuthContext from '@/components/LoginForm/auth-context';

let benchmarkToPost: BenchmarkDTO;
const Machine = () => {
	const { t } = useTranslation();
	const {
		date1: date1Default,
		date2: date2Default,
		timezone
	} = useFilterDateContext();

	const { size, isMobile } = useWindowContext();

	const authCtx = useContext(AuthContext);

	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const deviceId = params.get('id') || undefined;

	const titleCtx = useContext(MainContext);
	const [titleLoaded, setTitleLoaded] = useState<boolean>(false);

	const [date1Usage, setDate1Usage] = useState(date1Default);
	const date1InputRefUsage = useRef(null);

	const [date2Usage, setDate2Usage] = useState(date2Default);
	const date2InputRefUsage = useRef(null);

	const [date1Assist, setDate1Assist] = useState(date1Default);
	const date1InputRefAssist = useRef(null);

	const [date2Assist, setDate2Assist] = useState(date2Default);
	const date2InputRefAssist = useRef(null);

	const [date1Route, setDate1Route] = useState(date1Default);
	const date1InputRefRoute = useRef(null);

	const [date2Route, setDate2Route] = useState(date2Default);
	const date2InputRefRoute = useRef(null);

	const [updatedBenchmarks, setUpdatedBenchmarks] = useState<BenchmarkDTO[]>([]);
	const [benchmarkErrors, setBenchmarkErrors] = useState<BenchmarkDTO.type[]>(
		[]
	);
	// const [updatedDevice, setUpdatedDevice] = useState<boolean>(false);
	const [deviceToPost, setDeviceToPost] = useState<DeviceDTO>();
	const [contractToPost, setContractToPost] = useState<ContractDTO>();

	const [deviceModel, setDeviceModel] = useState<string>();
	const [forcePCcatalogueUpdate, setForcePCcatalogueUpdate] =
		useState<boolean>(false);

	const [devImage, setDevImage] = useState<string | undefined>(
		StandardMachineIcon
	);

	const comboDateUsage = {
		idDate1: 'data1',
		nameDate1: 'Data1',
		valueDate1: date1Usage,
		idDate2: 'data2',
		nameDate2: 'Data2',
		valueDate2: date2Usage,
		min: 'string',
		max: 'string',
		labelName: 'string',
		textLabel: t('dashboard.period'),
		ref1: date1InputRefUsage,
		onChangeDate1: (e: any) => {
			setDate1Usage(e.target.value);
		},
		ref2: date2InputRefUsage,
		onChangeDate2: (e: any) => {
			setDate2Usage(e.target.value);
		}
	};

	const comboDateAssist = {
		idDate1: 'data1',
		nameDate1: 'Data1',
		valueDate1: date1Assist,
		idDate2: 'data2',
		nameDate2: 'Data2',
		valueDate2: date2Assist,
		min: 'string',
		max: 'string',
		labelName: 'string',
		textLabel: t('dashboard.period'),
		ref1: date1InputRefAssist,
		onChangeDate1: (e: any) => {
			setDate1Assist(e.target.value);
		},
		ref2: date2InputRefAssist,
		onChangeDate2: (e: any) => {
			setDate2Assist(e.target.value);
		}
	};

	const comboDateRoute = {
		idDate1: 'data1',
		nameDate1: 'Data1',
		valueDate1: date1Route,
		idDate2: 'data2',
		nameDate2: 'Data2',
		valueDate2: date2Route,
		min: 'string',
		max: 'string',
		labelName: 'string',
		textLabel: t('dashboard.period'),
		ref1: date1InputRefRoute,
		onChangeDate1: (e: any) => {
			setDate1Route(e.target.value);
		},
		ref2: date2InputRefRoute,
		onChangeDate2: (e: any) => {
			setDate2Route(e.target.value);
		}
	};

	const deviceDetails = useRemoteGet({
		remoteMethod: () => {
			if (typeof deviceId !== 'undefined') {
				return CustomDeviceResourceService.getDeviceById(parseInt(deviceId));
			}
		},
		lazy: false
	});

	const deviceMetadata = useRemoteGet({
		remoteMethod: () => {
			if (typeof deviceId !== 'undefined') {
				return CustomMetadataResourceService.getDeviceMetadata(parseInt(deviceId));
			}
		},
		lazy: false
	});

	const postBenchmarks = useRemotePost({
		remoteMethod: () => {
			if (typeof deviceId !== 'undefined') {
				return CustomBenchmarkResourceService.saveBenchmark(
					+deviceId,
					benchmarkToPost
				);
			}
		}
	});

	// const postDevice = useRemotePost({
	//   remoteMethod: () => {
	//     if (typeof deviceId !== 'undefined') {
	//       return CustomDeviceResourceService.updateDeviceName(+deviceId, deviceToPost!.name!);
	//     }
	//   }
	// })

	const activeContract = useRemoteGet({
		remoteMethod: () => {
			if (typeof deviceId !== 'undefined' && authCtx.companyID) {
				return CustomContractResourceService.getActiveContract(
					moment().utc().format(),
					moment().utc().format(),
					parseInt(deviceId),
					authCtx.companyID
				);
			}
		},
		lazy: true
	});

	const deviceNotifications = useRemoteGet({
		remoteMethod: () => {
			if (typeof deviceId !== 'undefined') {
				// In realtà le date non servono.
				return CustomDeviceDetailResourceService.getNotificationDevicePage(
					moment().utc().format(),
					moment().utc().format(),
					timezone,
					+deviceId
				);
			}
		},
		lazy: true
	});

	const benchmarksData = useRemoteGet({
		remoteMethod: () => {
			if (typeof deviceId !== 'undefined') {
				return CustomBenchmarkResourceService.getAllBenchmarksByDevice(
					parseInt(deviceId)
				);
			}
		},
		lazy: true
	});

	useEffect(() => {
		activeContract.fetch();
	}, [authCtx]);

	useEffect(() => {
		if (deviceMetadata.data && deviceMetadata.data.length !== 0) {
			deviceMetadata.data?.map((data) => {
				if (data.key === 'image' && data.value !== null) {
					setDevImage(data.value);
				}
			});
		}
	}, [deviceMetadata.data]);

	useEffect(() => {
		if (!titleLoaded) {
			if (typeof deviceDetails.data !== 'undefined') {
				titleCtx.setTitle(
					<>
						<Label
							text={deviceDetails.data.name ?? ''}
							className={styles.titleLabelBlue}
						/>
						<Label text={' Machine'} className={styles.titleLabel} />
					</>
				);
				setTitleLoaded(true);
				setDeviceModel(deviceDetails.data?.deviceModel);
				setDeviceToPost({ ...deviceDetails.data });
				deviceNotifications.fetch();
				benchmarksData.fetch();
			}
		}
	}, [deviceDetails]);

	useEffect(() => {
		if (typeof activeContract.data !== 'undefined') {
			setContractToPost(activeContract.data);
		}
	}, [activeContract]);

	useEffect(() => {
		if (deviceModel) {
			setForcePCcatalogueUpdate(true);
		}

		return () => {
			setForcePCcatalogueUpdate(false);
		};
	}, [deviceModel]);

	const setBenchmarksToUpdate = useCallback((value: string, type: string) => {
		const asNum = +value;

		if (isNaN(asNum)) {
			// error
			setBenchmarkErrors([...benchmarkErrors, type as BenchmarkDTO.type]);
		} else {
			setBenchmarkErrors(
				benchmarkErrors.filter((b) => b !== (type as BenchmarkDTO.type))
			);
			if (typeof deviceId !== 'undefined') {
				const b: BenchmarkDTO = {
					type: type as BenchmarkDTO.type,
					value: asNum,
					deviceId: +deviceId
				};
				setUpdatedBenchmarks((prevState) => {
					const replaceBenchmark = prevState.filter(
						(b) => b.type !== (type as BenchmarkDTO.type)
					);
					return [...replaceBenchmark, b];
				});
			}
		}
	}, []);

	// const updateDeviceOnChange = useCallback((key: keyof DeviceDTO, value: any) => {
	//   if (deviceToPost && deviceToPost[key] !== value) {
	//     setUpdatedDevice(true);
	//     const toUpdate = {...deviceToPost};
	//     toUpdate[key] = value;
	//     setDeviceToPost(toUpdate);
	//   }
	// }, [deviceToPost]);

	const usageColumnsAMR = {
		startTime: t('columns.machine.startTime'),
		autonomousUsageHDay: t('columns.machine.autonomousUsageHDay'),
		manualUsageHDay: t('columns.machine.manualUsageHDay'),
		trainingHDay: t('columns.machine.trainingHDay'),
		autonomousUsagePerc: t('columns.machine.autonomousUsagePerc'),
		m2PerHour: t('columns.machine.m2PerHour'),
		m2Cleaned: t('columns.machine.m2Cleaned'),
		autoDistance: t('columns.machine.autoDistance')
	};

	const usageColumnsToolsense = {
		startTime: t('columns.machine.startTime'),
		toolsenseRuntime: t('machine.toolsense.runtime'),
		toolsenseTotUtilization: t('machine.toolsense.total_utilization'),
		toolsenseTotWorkDone: t('machine.toolsense.total_work_done'),
		toolsenseBatteryLevel: t('machine.toolsense.battery_level'),
		toolsenseLastContacted: t('machine.toolsense.last_contacted')
	};

	const assistColumns = {
		dateStart: t('columns.machine.dateStart'),
		assist: t('columns.machine.assist'),
		routes: t('columns.machine.routes')
	};

	const routeColumns = {
		routeName: t('columns.machine.routeName'),
		routeLabel: t('columns.machine.routeLabel'),
		lastRun: t('columns.machine.lastRun'),
		autonomousTime: t('columns.machine.autonomousTime'),
		runsCompleted: t('columns.machine.runsCompleted'),
		totAssist: t('columns.machine.totAssist'),
		totHours: t('columns.machine.totHours'),
		areaCovered: t('columns.machine.m2Cleaned')
	};

	const catalogueColumns = {
		refPart: t('columns.machine.refPart'),
		description: t('columns.machine.description')
	};

	const [errorDataPeriod, setErrorDataPeriod] = useState(false);
	const buttonRefUsage = useRef<HTMLButtonElement>(null);
	const buttonRefAssist = useRef<HTMLButtonElement>(null);
	const buttonRefRoute = useRef<HTMLButtonElement>(null);

	const usageSearchFilter = 'usage';
	const assistSearchFilter = 'assist';
	const routeSearchFilter = 'route';

	/*
	In base alla sezione in cui vengono modificate le date nel campo di ricerca
	controlla i valori delle date ed effettua le query al db
	*/
	const applyFilters = useCallback(
		(dataSection: string) => {
			if (dataSection === usageSearchFilter && date1Usage <= date2Usage)
				setErrorDataPeriod(false);
			else if (dataSection === assistSearchFilter && date1Assist <= date2Assist)
				setErrorDataPeriod(false);
			else if (dataSection === routeSearchFilter && date1Route <= date2Route)
				setErrorDataPeriod(false);
			else {
				setErrorDataPeriod(true);
				setTimeout(() => {
					setErrorDataPeriod(false);
				}, 2000);
			}
		},
		[date1Usage, date2Usage, date1Assist, date2Assist, date1Route, date2Route]
	);

	const loader = () => {
		if (
			deviceDetails.loading ||
			deviceMetadata.loading ||
			deviceNotifications.loading ||
			benchmarksData.loading ||
			activeContract.loading
		)
			return (
				<span>
					<CustomLoader searchLoader={true} />
				</span>
			);
		else return <span></span>;
	};

	const toolsenseTranslator = (datakey: string | undefined) => {
		switch (datakey) {
			case 'asset_category':
				return t('machine.toolsense.asset_category');
			case 'availability':
				return t('machine.toolsense.availability');
			case 'image':
				return t('machine.toolsense.image');
			case 'imei':
				return t('machine.toolsense.imei');
			case 'location_uncertainty':
				return t('machine.toolsense.location_uncertainty');
			case 'public_id':
				return t('machine.toolsense.public_id');
			case 'runtime_with_offset_in_sec':
				return t('machine.toolsense.runtime_with_offset_in_sec');
			case 'total_utilization':
				return t('machine.toolsense.total_utilization');
			case 'total_work_done':
				return t('machine.toolsense.total_work_done');
			case 'battery_level':
				return t('machine.toolsense.battery_level');
			case 'battery_charging_time':
				return t('machine.toolsense.battery_charging_time');
			case 'runtime':
				return t('machine.toolsense.runtime');
			// case 'utilization':
			// 	return t('machine.toolsense.utilization');
			case 'work_done':
				return t('machine.toolsense.work_done');
			case 'number_of_charge_cycles':
				return t('machine.toolsense.number_of_charge_cycles');
			case 'asset_battery_voltage':
				return t('machine.toolsense.asset_battery_voltage');
			case 'machine_battery_level':
				return t('machine.toolsense.machine_battery_level');
			case 'module_signal_strength':
				return t('machine.toolsense.module_signal_strength');
			case 'module_battery_level':
				return t('machine.toolsense.module_battery_level');
			case 'suction_motor':
				return t('machine.toolsense.suction_motor');
			case 'brush_motor':
				return t('machine.toolsense.brush_motor');
			case 'lastContacted':
				return t('machine.toolsense.last_contacted');
			case 'account_number':
				return t('machine.toolsense.siteAccountNr');
			case 'next_check':
				return t('machine.toolsense.nextCheck');
			case 'days_next_check':
				return t('machine.toolsense.daysNextCheck');
			case 'next_maintenance':
				return t('machine.toolsense.nextMaintenance');
			case 'address':
				return t('machine.toolsense.address');
			default:
				return 'unknown';
		}
	};

	const headerButtons =
		!deviceMetadata.data || deviceMetadata.data.length === 0
			? [
					{
						id: 'benchmarks',
						name: t('machine.benchmarks')
					},
					{
						id: 'usage',
						name: t('machine.usage')
					},
					{
						id: 'assist',
						name: t('machine.assists')
					},
					{
						id: 'routes',
						name: t('machine.routes')
					},
					{
						id: 'catalogue',
						name: t('machine.p&cCatalogue')
					}
			  ]
			: [
					{
						id: 'usage',
						name: t('machine.usage')
					}
			  ];

	return (
		<div className={styles.main_container}>
			{isMobile && ( //---------------------------HEADER MOBILE
				<div className={styles.title_container}>
					<Label text={t('sidebar.amrMachines')} className={styles.subHeader} />
					{/* <Label text={allMachines + ' '} className={styles.title_labelColored} /> */}
					<Label text={deviceDetails.data?.rin ?? ''} className={''} />
					{/* <div className={styles.onlineContainer}>
                        <div className={styles.onlineRoundCss}/>
                        <Label text='Online' className={styles.onlineLabel}/>
                    </div> */}
				</div>
			)}
			{!isMobile && ( //--------------------------Hidden Button on Mobile
				<div className={styles.horizontalContainer}>
					{headerButtons.map((val) => (
						<Link
							to={{
								search: `?${params}`,
								hash: `#${val.id}`
							}}
							className={styles.headerButton}
						>
							{val.name}
						</Link>
					))}
				</div>
			)}

			<div className={styles.horizontalContainer}>
				{!isMobile && (
					<Box
						className={styles.imgMain_Container}
						children={
							<>
								<div className={styles.imgContainer}>
									<Img className={styles.machineImg} alt={''} src={devImage} />
								</div>
								<div
									className={styles.verticalContainer}
									style={{ height: '100%', justifyContent: 'space-between' }}
								>
									<div className={styles.imgButtonContainer}>
										<Button
											name={(!deviceMetadata.data || deviceMetadata.data.length === 0) ? 'AMR' : 'MACHINE WITH TELEMETRY'}
											className={styles.imgButton}
											onClick={() => {}}
										/>
									</div>
									<div
										className={styles.horizontalContainer}
										style={{ justifyContent: 'end', margin: '0 2rem 1rem 0' }}
									>
										<Button
											name={''}
											className={styles.editButton}
											onClick={() => {}}
											iconStart={deleteIcon}
										/>
										<Button
											name={''}
											className={styles.editButton}
											onClick={() => {}}
											iconStart={editIcon}
										/>
										<Img
											className={styles.plusButton}
											alt={''}
											src={plusIcon}
											onClick={() => {}}
										/>
									</div>
								</div>
							</>
						}
					/>
				)}

				{!isMobile && (
					<Box
						className={styles.map_container}
						children={
							<Maps
								className={styles.map}
								sites={
									deviceDetails.data?.sitesDTO ? [deviceDetails.data?.sitesDTO] : []
								}
							/>
						}
					/>
				)}
				{/*--------------------------------------NOTIFICATION START----------------------------------------------------- */}
				<div className={styles.notificationContainerMobile}>
					{isMobile && (
						<div className={styles.notificationTitleContainer}>
							<Img
								src={notification_bell}
								alt=""
								className={styles.notificationIcon}
							/>
							<Label
								text={t('notifications.title')}
								className={styles.notificationLabel}
							/>
						</div>
					)}
					<Box
						className={styles.notificationMain_Container}
						children={
							<>
								<div className={styles.notificationContainer}>
									{deviceNotifications.data?.map((data, index) => {
										return (
											<>
												<MachineDetailNotification
													status={data.status as string}
													date={data.data ?? ''}
													type={data.type ?? ''}
												/>
												{index + 1 !== deviceNotifications.data?.length && (
													<div className={styles.hr} />
												)}
											</>
										);
									})}
								</div>
								{/*<div className={styles.notificationBtnContainer}>*/}
								{/*    <Button name={'See All'} className={styles.saveBtn} onClick={() => {*/}
								{/*    }}/>*/}
								{/*</div>*/}
							</>
						}
					/>
				</div>
				{/*--------------------------------------NOTIFICATION END----------------------------------------------------- */}
			</div>

			{/*--------------------------------GENERAL BOX START-------------------------------------- */}
			<Collapse title={t('machine.title')}>
				<Box
					className={styles.box_container}
					children={
						<div className={styles.box_subContainer}>
							{!isMobile && (
								<Label text={t('machine.details')} className={styles.titleLabel} />
							)}
							<div className={styles.inputContainer}>
								<InputField
									type={'text'}
									placeholder={t('machine.customerName')}
									title={''}
									required={false}
									label={t('machine.customerName')}
									labelClassName={styles.label}
									value={
										deviceDetails.data?.company != null
											? deviceDetails.data?.company?.parent
												? deviceDetails.data?.company?.parent?.name +
												  ' - ' +
												  deviceDetails.data?.company?.name
												: deviceDetails.data?.company?.name
											: 'Not assigned'
									}
									disabled={true}
								/>
								<InputField
									type={'text'}
									placeholder={t('machine.customerId')}
									title={''}
									required={false}
									label={t('machine.customerId')}
									labelClassName={styles.label}
									value={
										deviceMetadata.data &&
										deviceMetadata.data.length !== 0 &&
										deviceMetadata.data.find((item) => item.key === 'account_number')
											?.value !== undefined
											? deviceMetadata.data.find((item) => item.key === 'account_number')
													?.value
											: deviceDetails.data?.company?.companyId
									}
									disabled={true}
								/>
								<InputField
									type={'text'}
									placeholder={t('machine.site')}
									title={''}
									required={false}
									label={t('machine.site')}
									labelClassName={styles.label}
									value={deviceDetails.data?.siteName}
									disabled={true}
								/>
								{/*<InputField type={'text'} placeholder={t('machine.machineName')} title={''} required={false}
                            label={t('machine.machineName')} labelClassName={styles.label}
                            value={updatedDevice && deviceToPost ? deviceToPost.name : deviceDetails.data?.name}
                            onChange={
                              (event) => {
                                updateDeviceOnChange('name', event.target.value);
                              }}
                />*/}
							</div>
							<div className={styles.inputContainer}>
								<InputField
									type={'text'}
									placeholder={t('machine.serial')}
									title={''}
									required={false}
									label={t('machine.serial')}
									labelClassName={styles.label}
									value={'' + deviceDetails.data?.serialNr}
									disabled={true}
								/>
								{(!deviceMetadata.data || deviceMetadata.data.length === 0) && (
									<InputField
										type={'text'}
										placeholder={t('machine.rin')}
										title={''}
										required={false}
										label={t('machine.rin')}
										labelClassName={styles.label}
										value={deviceDetails.data?.rin}
										disabled={true}
									/>
								)}
								<InputField
									type={'text'}
									placeholder={t('machine.machineModel')}
									title={''}
									required={false}
									label={t('machine.machineModel')}
									labelClassName={styles.label}
									value={deviceDetails.data?.deviceModel}
									disabled={true}
								/>
								{/* <InputField type={'text'} placeholder={t('machine.version')} title={''} required={false}
                            label={t('machine.version')} labelClassName={styles.label}
                            value={updatedDevice && deviceToPost ? deviceToPost.version : deviceDetails.data?.version}
                            onChange={
                                (event) => {
                                    updateDeviceOnChange('version', event.target.value);
                                }}
                /> */}
							</div>
							{deviceToPost && contractToPost && (
								<div className={styles.inputContainer}>
									<InputField
										type={'date'}
										placeholder={t('machine.warrStartDate')}
										title={''}
										required={false}
										label={t('machine.warrStartDate')}
										labelClassName={styles.label}
										value={moment(deviceToPost.warranStartDate).format('YYYY-MM-DD')}
										/*onChange={
                              (event) => {
                                updateDeviceOnChange('warranStartDate', formDateToTimestamp(event.target.value));
                              }}*/
										disabled={true}
									/>
									<InputField
										type={'date'}
										placeholder={t('machine.deployment')}
										title={''}
										required={false}
										label={t('machine.deployment')}
										labelClassName={styles.label}
										value={moment(deviceToPost.deploymentDate).format('YYYY-MM-DD')}
										/*onChange={
                              (event) => {
                                updateDeviceOnChange('deploymentDate', formDateToTimestamp(event.target.value));
                              }
                            }*/
										disabled={true}
									/>
								</div>
							)}
							{deviceToPost && contractToPost && (
								<div className={styles.inputContainer}>
									<InputField
										type={'text'}
										placeholder={t('machine.contractType')}
										title={''}
										required={false}
										label={t('machine.contractType')}
										labelClassName={styles.label}
										value={contractToPost.contractType}
										/*onChange={
                              (event) => {
                                updateDeviceOnChange('contractType', event.target.value);
                              }}*/
										disabled={true}
									/>
									<InputField
										type={'date'}
										placeholder={t('machine.contractStartDate')}
										title={''}
										required={false}
										label={t('machine.contractStartDate')}
										labelClassName={styles.label}
										value={moment(contractToPost.contractStartDate).format('YYYY-MM-DD')}
										/*onChange={
                              (event) => {
                                updateDeviceOnChange('contractStartDate', formDateToTimestamp(event.target.value));
                              }}*/
										disabled={true}
									/>
									<InputField
										type={'date'}
										placeholder={t('machine.contractEndDate')}
										title={''}
										required={false}
										label={t('machine.contractEndDate')}
										labelClassName={styles.label}
										value={moment(contractToPost.contractEndDate).format('YYYY-MM-DD')}
										/*onChange={
                            (event) => {
                                updateDeviceOnChange('contractEndDate', formDateToTimestamp(event.target.value));
                            }}*/
										disabled={true}
									/>
								</div>
							)}

							{/* <div className={styles.saveButtonContainer}>
                <Button name={t('machine.save')} className={styles.saveBtn} onClick={() => {
                if (updatedDevice) {
                    postDevice.fetch()
                        .then(() => {
                        return deviceDetails.fetch();
                        }).then(() => {
                    setUpdatedDevice(false);
                    })
                }
                }}/>
              </div> */}
							{isMobile && (
								<Box
									className={styles.map_container}
									children={
										<Maps
											className={styles.map}
											sites={
												deviceDetails.data?.sitesDTO ? [deviceDetails.data?.sitesDTO] : []
											}
										/>
									}
								/>
							)}
						</div>
					}
				/>
			</Collapse>
			{/*--------------------------------GENERAL BOX END-------------------------------------- */}

			{/*--------------------------------TOOLSENSE BOX START-------------------------------------- */}
			{deviceMetadata.data && deviceMetadata.data.length !== 0 && (
				<Collapse title={t('machine.details')} isToolsenseData={false}>
					<Box
						className={styles.box_container}
						children={
							<div className={styles.box_subContainer} id="toolsense">
								<div className={styles.inputContainer}>
									{deviceMetadata.data?.map((data, index) => {
										const translatedKey = toolsenseTranslator(data.key);
										return data.key !== 'image' && translatedKey !== 'unknown' ? (
											<InputField
												type={'text'}
												placeholder={translatedKey}
												title={''}
												required={false}
												label={translatedKey}
												labelClassName={styles.label}
												value={data.value}
												disabled={true}
											/>
										) : null;
									})}
								</div>
							</div>
						}
					/>
				</Collapse>
			)}
			{/*--------------------------------TOOLSENSE BOX END-------------------------------------- */}

			{/*--------------------------------BENCHMARKS BOX START-------------------------------------- */}
			{(!deviceMetadata.data || deviceMetadata.data.length === 0) && (
				<Collapse title={t('machine.benchmarks')}>
					<Box
						className={styles.box_container}
						children={
							<div className={styles.box_subContainer} id="benchmarks">
								{!isMobile && (
									<Label text={t('machine.benchmarks')} className={styles.titleLabel} />
								)}
								<div className={styles.inputBenchContainer}>
									<InputField
										type={'text'}
										placeholder={t('machine.totDaysPerWeek')}
										title={''}
										required={false}
										label={t('machine.totDaysPerWeek')}
										labelClassName={styles.benchLabel}
										value={
											updatedBenchmarks.length > 0 &&
											updatedBenchmarks.find(
												(b) => b.type && b.type === BenchmarkDTO.type.TOTAL_DAY_PER_WEEK
											) !== undefined
												? '' +
												  updatedBenchmarks.find(
														(b) => b.type && b.type === BenchmarkDTO.type.TOTAL_DAY_PER_WEEK
												  )?.value
												: typeof benchmarksData.data !== 'undefined' &&
												  benchmarksData.data.find(
														(b) => b.type && b.type === BenchmarkDTO.type.TOTAL_DAY_PER_WEEK
												  ) !== undefined
												? '' +
														benchmarksData.data.find(
															(b) => b.type && b.type === BenchmarkDTO.type.TOTAL_DAY_PER_WEEK
														)?.value || ''
												: ''
										}
										onChange={(event) =>
											setBenchmarksToUpdate(event.target.value, 'TOTAL_DAY_PER_WEEK')
										}
										error={
											benchmarkErrors.find((t) => t === type.TOTAL_DAY_PER_WEEK) !==
											undefined
										}
									/>
									<InputField
										type={'text'}
										placeholder={t('machine.totHoursPerDay')}
										title={''}
										required={false}
										label={t('machine.totHoursPerDay')}
										labelClassName={styles.benchLabel}
										value={
											updatedBenchmarks.length > 0 &&
											updatedBenchmarks.find(
												(b) => b.type && b.type === BenchmarkDTO.type.TOTAL_HOUR_PER_DAY
											) !== undefined
												? '' +
												  updatedBenchmarks.find(
														(b) => b.type && b.type === BenchmarkDTO.type.TOTAL_HOUR_PER_DAY
												  )?.value
												: typeof benchmarksData.data !== 'undefined' &&
												  benchmarksData.data.find(
														(b) => b.type && b.type === BenchmarkDTO.type.TOTAL_HOUR_PER_DAY
												  ) !== undefined
												? '' +
												  +(
														(benchmarksData.data.find(
															(b) => b.type && b.type === BenchmarkDTO.type.TOTAL_HOUR_PER_DAY
														)?.value || 0) / 60
												  ).toFixed(2)
												: ''
										}
										onChange={(event) =>
											setBenchmarksToUpdate(event.target.value, 'TOTAL_HOUR_PER_DAY')
										}
										error={
											benchmarkErrors.find((t) => t === type.TOTAL_HOUR_PER_DAY) !==
											undefined
										}
									/>
									<InputField
										type={'text'}
										placeholder={t('machine.avgAutoUsage')}
										title={''}
										required={false}
										label={t('machine.avgAutoUsage')}
										labelClassName={styles.benchLabel}
										value={
											updatedBenchmarks.length > 0 &&
											updatedBenchmarks.find(
												(b) =>
													b.type && b.type === BenchmarkDTO.type.AVERAGE_AUTONOMUS_USAGE
											) !== undefined
												? '' +
												  updatedBenchmarks.find(
														(b) =>
															b.type && b.type === BenchmarkDTO.type.AVERAGE_AUTONOMUS_USAGE
												  )?.value
												: typeof benchmarksData.data !== 'undefined' &&
												  benchmarksData.data.find(
														(b) =>
															b.type && b.type === BenchmarkDTO.type.AVERAGE_AUTONOMUS_USAGE
												  ) !== undefined
												? '' +
														benchmarksData.data.find(
															(b) =>
																b.type && b.type === BenchmarkDTO.type.AVERAGE_AUTONOMUS_USAGE
														)?.value || ''
												: ''
										}
										onChange={(event) =>
											setBenchmarksToUpdate(event.target.value, 'AVERAGE_AUTONOMUS_USAGE')
										}
										error={
											benchmarkErrors.find((t) => t === type.AVERAGE_AUTONOMUS_USAGE) !==
											undefined
										}
									/>
									<InputField
										type={'text'}
										placeholder={t('machine.assistPerHour')}
										title={''}
										required={false}
										label={t('machine.assistPerHour')}
										labelClassName={styles.benchLabel}
										value={
											updatedBenchmarks.length > 0 &&
											updatedBenchmarks.find(
												(b) => b.type && b.type === BenchmarkDTO.type.ASSIST_PER_HOUR
											) !== undefined
												? '' +
												  updatedBenchmarks.find(
														(b) => b.type && b.type === BenchmarkDTO.type.ASSIST_PER_HOUR
												  )?.value
												: typeof benchmarksData.data !== 'undefined' &&
												  benchmarksData.data.find(
														(b) => b.type && b.type === BenchmarkDTO.type.ASSIST_PER_HOUR
												  ) !== undefined
												? '' +
														benchmarksData.data.find(
															(b) => b.type && b.type === BenchmarkDTO.type.ASSIST_PER_HOUR
														)?.value || ''
												: ''
										}
										onChange={(event) =>
											setBenchmarksToUpdate(event.target.value, 'ASSIST_PER_HOUR')
										}
										error={
											benchmarkErrors.find((t) => t === type.ASSIST_PER_HOUR) !== undefined
										}
									/>
								</div>
								<div className={styles.saveButtonContainer}>
									<Button
										name={t('machine.save')}
										className={styles.saveBtn}
										onClick={() => {
											if (updatedBenchmarks.length > 0) {
												updatedBenchmarks.map((b) => {
													benchmarkToPost = {
														type: b.type as BenchmarkDTO.type,
														value:
															b.type === 'TOTAL_HOUR_PER_DAY' && b.value !== undefined
																? b.value * 60
																: b.value,
														deviceId: b.deviceId
													};
													postBenchmarks.fetch();
												});
												benchmarkToPost = {};
											}
										}}
									/>
								</div>
							</div>
						}
					/>
				</Collapse>
			)}
			{/*--------------------------------BENCHMARKS BOX END-------------------------------------- */}

			{/*--------------------------------USAGE BOX START-------------------------------------- */}
			<Collapse title={t('machine.usage')}>
				<Box
					className={styles.box_container}
					children={
						<div className={styles.box_subContainer} id="usage">
							{!isMobile && (
								<Label text={t('machine.usage')} className={styles.titleLabel} />
							)}
							<div className={styles.tableHeader}>
								<FilterBar
									filters={[
										{
											type: 'Date',
											selectData: comboDateUsage,
											boxClassName: '',
											dataError: errorDataPeriod
										}
									]}
								/>
								<div className={styles.downloadBtn_container}>
									<DownloadButton
										name={t('machine.download')}
										className={styles.downloadBtn}
										iconStart={downloadIcon}
										filename={`machine_${deviceDetails.data?.serialNr}_usage.csv`}
										columns={
											!deviceMetadata.data || deviceMetadata.data.length === 0
												? usageColumnsAMR
												: usageColumnsToolsense
										}
										remoteMethod={() => {
											return CustomDeviceDetailResourceService.getUsageDevicePageUnpaged(
												formDateToTimestamp(date1Usage),
												formDateToTimestamp(date2Usage, true),
												timezone,
												parseInt(deviceId!),
												undefined
											);
										}}
									/>
								</div>
							</div>
							<div className={styles.searchBtnContainer}>
								<Button
									ref={buttonRefUsage}
									name={t('dashboard.applyFilters')}
									className={styles.searchBtn}
									onClick={() => applyFilters(usageSearchFilter)}
								/>
								{loader()}
							</div>
							<PagingContextProvider
								searchRef={buttonRefUsage}
								queryParams={{
									date1: date1Usage,
									date2: date2Usage,
									deviceId: deviceId
								}}
								remoteMethod={(queryParams, paging) => {
									return () => {
										return CustomDeviceDetailResourceService.getUsageDevicePage(
											formDateToTimestamp(queryParams.date1!),
											formDateToTimestamp(queryParams.date2!, true),
											timezone,
											parseInt(queryParams.deviceId!),
											undefined,
											paging.pageNumber,
											paging.pageSize,
											paging.sortBy,
											paging.sortOrder
										);
									};
								}}
							>
								<div className={styles.tableContainer}>
									<Table
										columns={
											!deviceMetadata.data || deviceMetadata.data.length === 0
												? usageColumnsAMR
												: usageColumnsToolsense
										}
										type={'simpleNoAction'}
										paging={true}
									/>
								</div>
							</PagingContextProvider>
						</div>
					}
				/>
			</Collapse>
			{/*--------------------------------USAGE BOX END-------------------------------------- */}

			{/*--------------------------------ASSIST BOX START-------------------------------------- */}
			{(!deviceMetadata.data || deviceMetadata.data.length === 0) && (
				<Collapse title={t('machine.assist')}>
					<Box
						className={styles.box_container}
						children={
							<div className={styles.box_subContainer} id="assist">
								{!isMobile && (
									<Label text={t('machine.assist')} className={styles.titleLabel} />
								)}
								<div className={styles.tableHeader}>
									<FilterBar
										filters={[
											{
												type: 'Date',
												selectData: comboDateAssist,
												boxClassName: '',
												dataError: errorDataPeriod
											}
										]}
									/>
									<div className={styles.downloadBtn_container}>
										<DownloadButton
											name={t('machine.download')}
											className={styles.downloadBtn}
											iconStart={downloadIcon}
											filename={`machine_${deviceDetails.data?.serialNr}_assist.csv`}
											columns={assistColumns}
											remoteMethod={() => {
												if (typeof deviceId !== 'undefined') {
													return CustomDeviceDetailResourceService.getAssistDevicePageUnpaged(
														formDateToTimestamp(date1Assist),
														formDateToTimestamp(date2Assist, true),
														timezone,
														parseInt(deviceId),
														undefined
													);
												}
											}}
										/>
									</div>
								</div>
								<div className={styles.searchBtnContainer}>
									<Button
										ref={buttonRefAssist}
										name={t('dashboard.applyFilters')}
										className={styles.searchBtn}
										onClick={() => applyFilters(assistSearchFilter)}
									/>
									{loader()}
								</div>
								<PagingContextProvider
									searchRef={buttonRefAssist}
									queryParams={{
										deviceId: deviceId,
										date1: date1Assist,
										date2: date2Assist
									}}
									remoteMethod={(queryParams, paging) => {
										return () => {
											if (typeof queryParams.deviceId !== 'undefined') {
												return CustomDeviceDetailResourceService.getAssistDevicePage(
													formDateToTimestamp(queryParams.date1!),
													formDateToTimestamp(queryParams.date2!, true),
													timezone,
													parseInt(queryParams.deviceId),
													undefined,
													paging.pageNumber,
													paging.pageSize,
													paging.sortBy,
													paging.sortOrder
												);
											}
										};
									}}
								>
									<div className={styles.tableContainer}>
										<Table
											columns={assistColumns}
											type={'simpleNoAction'}
											paging={true}
										/>
									</div>
								</PagingContextProvider>
							</div>
						}
					/>
				</Collapse>
			)}
			{/*--------------------------------ASSIST BOX END-------------------------------------- */}

			{/*--------------------------------ROUTES BOX START-------------------------------------- */}
			{(!deviceMetadata.data || deviceMetadata.data.length === 0) && (
				<Collapse title={t('machine.routes')}>
					<Box
						className={styles.box_container}
						children={
							<div className={styles.box_subContainer} id="routes">
								{!isMobile && (
									<Label text={t('machine.routes')} className={styles.titleLabel} />
								)}
								<div className={styles.tableHeader}>
									<FilterBar
										filters={[
											{
												type: 'Date',
												selectData: comboDateRoute,
												boxClassName: '',
												dataError: errorDataPeriod
											}
										]}
									/>
									<div className={styles.downloadBtn_container}>
										<DownloadButton
											name={t('machine.download')}
											className={styles.downloadBtn}
											iconStart={downloadIcon}
											columns={routeColumns}
											filename={`machine_${deviceDetails.data?.serialNr}_routes.csv`}
											remoteMethod={() => {
												if (typeof deviceId !== 'undefined') {
													return CustomDeviceDetailResourceService.getRoutesDevicePageUnpaged(
														formDateToTimestamp(date1Route),
														formDateToTimestamp(date2Route, true),
														timezone,
														parseInt(deviceId),
														undefined
													);
												}
											}}
										/>
									</div>
								</div>
								<div className={styles.searchBtnContainer}>
									<Button
										ref={buttonRefRoute}
										name={t('dashboard.applyFilters')}
										className={styles.searchBtn}
										onClick={() => applyFilters(routeSearchFilter)}
									/>
									{loader()}
								</div>
								<PagingContextProvider
									searchRef={buttonRefRoute}
									queryParams={{
										deviceId: deviceId,
										date1: date1Route,
										date2: date2Route
									}}
									remoteMethod={(queryParams, paging) => {
										return () => {
											if (typeof deviceId !== 'undefined') {
												return CustomDeviceDetailResourceService.getRoutesDevicePage(
													formDateToTimestamp(queryParams.date1!),
													formDateToTimestamp(queryParams.date2!, true),
													timezone,
													parseInt(deviceId),
													undefined,
													paging.pageNumber,
													paging.pageSize,
													paging.sortBy,
													paging.sortOrder
												);
											}
										};
									}}
								>
									<div className={styles.tableContainer}>
										<Table columns={routeColumns} type={'simpleNoAction'} paging={true} />
									</div>
								</PagingContextProvider>
							</div>
						}
					/>
				</Collapse>
			)}
			{/*--------------------------------ROUTES BOX END-------------------------------------- */}

			{/*--------------------------------P&CCATALOGUE BOX START-------------------------------------- */}
			{(!deviceMetadata.data || deviceMetadata.data.length === 0) && (
				<PagingContextProvider
					queryParams={{ deviceModel: deviceModel }}
					remoteMethod={(queryParams, paging) => {
						return () => {
							if (typeof queryParams.deviceModel !== 'undefined') {
								return CustomPcCatalogueResourceService.getAllPcCatalougueByModel(
									queryParams.deviceModel.toUpperCase() + 'AMR',
									paging.pageNumber,
									paging.pageSize,
									paging.sortBy,
									paging.sortOrder
								);
							}
						};
					}}
					forceTrigger={forcePCcatalogueUpdate}
				>
					<Collapse title={t('machine.p&cCatalogue')}>
						<Box
							className={styles.box_container}
							children={
								<div className={styles.box_subContainer} id="catalogue">
									{!isMobile && (
										<Label
											text={t('machine.p&cCatalogue')}
											className={styles.titleLabel}
										/>
									)}
									<div
										className={styles.tableContainer}
										style={isMobile ? { marginTop: 0 } : { marginTop: '1rem' }}
									>
										<Table
											columns={catalogueColumns}
											type={'simpleNoAction'}
											paging={true}
										/>
									</div>
								</div>
							}
						/>
					</Collapse>
				</PagingContextProvider>
			)}
			{/*--------------------------------P&CCATALOGUE BOX END-------------------------------------- */}
		</div>
	);
};

export default Machine;
