import styles from './Table.module.css'
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import { usePagingContext } from "@/contexts/paging-context";
import CustomLoader from '../CustomLoader';
import { useMemo } from 'react';


export type TableProps<T extends Record<string, any>, K extends Partial<Record<Extract<keyof T, string>, string>>> = {
    columns: K;
    data?: Array<T>;
    dataLoading?: boolean
    break?: {};
    type: "sitesRanking" | "amrRanking" | "simpleNoAction" | "simpleAction" | "simpleMap" | "collapsed" | "notification" | "amrRankingOnlyAction" | "amrRankingWithoutAction" | "sitesRankingOnlyAction" | "sitesRankingWithoutAction"
    color?: "green" | "orange" | "blue"
    paging?: boolean;
};

const Table = <T extends Record<string, any>, K extends Partial<Record<Extract<keyof T, string>, string>>>({
    columns,
    data,
    dataLoading,
    type,
    color,
    paging
}: TableProps<T, K>) => {


    let arrayData: Array<T>;
    const { remoteGet, } = usePagingContext();
    if (typeof data === 'undefined') {
        arrayData = remoteGet.data?.content || [];
    } else {
        arrayData = data || [];
    }
    const loader = useMemo(() => {
        return <tbody>
            <td colSpan={Object.keys(columns).length + 1}>
                <CustomLoader />
            </td>
        </tbody>
    },[columns])

    switch (type) {
        case "sitesRanking":
        case "amrRankingOnlyAction":
        case "amrRankingWithoutAction":
        case "sitesRankingOnlyAction":
        case "sitesRankingWithoutAction":
        case "amrRanking": {
            return (
                <>
                    <div className={styles.tableDiv}>
                        <table className={styles.table}>
                            <Header columns={columns} type={type} />
                            {(remoteGet.loading || dataLoading) ?
                                loader :
                                <Body columns={columns} data={arrayData} type={type} color={color} />
                            }
                        </table>

                    </div>
                    {paging && <Footer />}
                </>
            );
        }
        case "simpleNoAction":
        case "simpleAction":
        case "simpleMap":
        case "collapsed": {
            return (
                <>
                    <div className={styles.tableDiv}>
                        <table className={styles.table}>
                            <Header columns={columns} type={type} />
                            {(remoteGet.loading || dataLoading) ?
                                loader :
                                <Body columns={columns} data={arrayData} type={type} />
                            }
                        </table>

                    </div>
                    {paging && <Footer />}
                </>
            );
        }
        case "notification": {
            return (
                <>
                    <div className={styles.tableDiv}>
                        <table className={styles.table}>
                            <Header columns={columns} type={type} />
                            <Body columns={columns} data={arrayData} type={type} />
                        </table>

                    </div>
                    {paging && <Footer />}
                </>
            );
        }
        default: {
            return (<></>);
        }
    }

};

export default Table;

