import Label from '../Label';
import styles from './InputText.module.css';
import React, { ChangeEventHandler } from 'react';

interface Props {
	type: string;
	placeholder: string;
	title: string;
	required: boolean;
	label?: string;
	labelClassName?: string;
	value?: string;
	// ref?: React.MutableRefObject<undefined>
	ref?: React.RefObject<HTMLInputElement>;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	error?: boolean;
	disabled?: boolean;
}

const InputField = (props: Props) => {
	const classes = props.type === 'date' ? [styles.input_date] : [styles.input];
	if (props.error) {
		classes.push(styles.input_error);
	}

	return (
		<>
			<div className={styles.form_group}>
				{props.label && (
					<Label text={props.label} className={props.labelClassName ?? ''} />
				)}
				<input
					className={classes.join(' ')}
					title={props.title}
					type={props.type}
					placeholder={props.placeholder}
					ref={props.ref}
					onChange={props.onChange}
					value={props.value}
					disabled={props.disabled}
				/>
			</div>
		</>
	);
};

export default InputField;
