import LabelBenchmarkIcon from '@/components/LabelBenchmarkIcon';
import styles from './Table.module.css'
import ReportScore from '@/components/ReportScore';
import Img from '../Img';
import thumbUpIcon from '@Assets/images/thumbUp.svg'
import thumpDownIcon from '@Assets/images/thumbDown.svg'
import warningIcon from '@Assets/images/warning.svg'
import {NotificationPageOutputDTO} from "@/services/openapi";
import {formatDate, translateAssistNotification} from "@/utils";
import {useTranslation} from "react-i18next";

import checkboxOn from '@Assets/images/checkbox_on.svg'
import checkboxOff from '@Assets/images/checkbox_off.svg'
import {usePagingContext} from "@/contexts/paging-context";


type CellProps = {
    rowNumber: number;
    id: string;
    data: string;
    index: number;
    type: "sitesRanking" | "amrRanking" | "simpleNoAction" | "simpleAction" | "simpleMap" | "collapsed" | "notification" | "normal" | "amrRankingOnlyAction" | "amrRankingWithoutAction" | "sitesRankingOnlyAction" | "sitesRankingWithoutAction"
    color?: "green" | "orange" | "blue";
    rowData?: Record<string, any>;
};

const Cell = ({rowNumber, id, data, index, type, color, rowData}: CellProps) => {

    const {t, i18n} = useTranslation();
    const {pageNumber, pageSize, totalPages, totalElements} = usePagingContext();

    if (id === 'date'
        || id === 'data'
        || id.endsWith('Date')
        || (id.endsWith('Time') && id !== 'autonomousTime')
        || id === 'lastRun'
        || id === 'dateStart') {
        data = formatDate(data);
    }
    if (id === 'assist') {
        if (data === data.toUpperCase()) {
            if (i18n.exists(`assists.reasons.${data}`)) {
                data = t(`assists.reasons.${data}`);
            } else {
                data = t('assists.reason.OTHER', {assist: data});
            }
        }
    }
    if (id === 'runsCompleted') {
        if (data) {
            return <td className={styles.td}>
                <Img className={styles.checkboxOn} alt="True" src={checkboxOn}/>
            </td>
        } else {
            return <td className={styles.td}>
                <Img className={styles.checkboxOff} alt="False" src={checkboxOff}/>
            </td>
        }
    }

    if (index == 0) {
        switch (type) {
            // case "amrRanking":
            // case "amrRankingOnlyAction":
            // case "amrRankingWithoutAction":
            case "sitesRankingOnlyAction":
            case "sitesRankingWithoutAction":
            case "sitesRanking": {
                if (color === "orange") {
                    return (
                        <td className={styles.tdFirst} style={{color: "#FC8D41"}}>
                            {rowNumber + (pageSize * pageNumber)}
                        </td>
                    );
                } else if (color === "green") {
                    return (
                        <td className={styles.tdFirst} style={{color: "#8BC93D"}}>
                            {rowNumber + (pageSize * pageNumber)}
                        </td>
                    );
                } else {
                    return (
                        <td className={styles.tdFirst} style={{color: "#20ACD9"}}>
                            {rowNumber + (pageSize * pageNumber)}
                        </td>
                    );
                }

            }
            case "notification": {
                return (
                    <td className={styles.tdFirst} style={{color: "#20ACD9"}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: 'center'}}>
                            <Img
                                className={data === NotificationPageOutputDTO.status.WARNING
                                    ? styles.alertNotificationIconContainer :
                                    (data === NotificationPageOutputDTO.status.OK ?
                                        styles.positiveNotificationIconContainer :
                                        styles.negativeNotificationIconContainer)}
                                alt={''}
                                src={data === NotificationPageOutputDTO.status.WARNING ?
                                    warningIcon :
                                    (data === NotificationPageOutputDTO.status.OK ? thumbUpIcon : thumpDownIcon)
                                }/>
                        </div>
                    </td>
                );
            }
            default: {
                return (
                    <td className={styles.td}>
                        {data}
                    </td>
                );

            }
        }

    } else {
        switch (type) {
            case "sitesRankingWithoutAction":
            case "sitesRankingOnlyAction":
            case "sitesRanking": {
                switch (id) {
                    case "totalUsagePerDay":
                    case "autonomusUsage":
                    case "assistPerDay":
                    case "manualHours": {
                        return (
                            <td className={styles.td}>
                                <LabelBenchmarkIcon id={id} label1={data}
                                                    benchmarks={rowData ? rowData['benchmark'] : []}/>

                            </td>
                        );
                    }
                    case "score": {
                        return (<td className={styles.td}>
                            <ReportScore score={+data}/>
                        </td>);
                    }
                }
                break;
            }
            case "notification": {
                return (
                    <td className={styles.td}>
                        {id === 'information' && rowData ? translateAssistNotification(t, rowData['information'], rowData['status']) : data}
                    </td>
                );
            }
            default: {
                return (
                    <td className={styles.td}>
                        {data}
                    </td>
                );

            }
        }

    }
    return (
        <td className={styles.td}>
            {data}
        </td>
    );

};

export default Cell;
