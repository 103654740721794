import Sidebar from '@Layout/Sidebar'
import Header from '@Layout/Header'
import styles from './Layout.module.css'
import {useContext, useState} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import LoginPage from '@Pages/LoginPage';
import Dashboard from '@Pages/Dashboard'
import Assist from '@Pages/Assist'
import Sites from '@Pages/Sites'
import Reports from '@Pages/Reports'
import AmrMachines from '@Pages/AMRMachines';
import Profile from '@Pages/Profile'
import Site from '@/pages/Site'
import AuthContext from '../LoginForm/auth-context'
import Machine from '@/pages/Machine'
import News from '@/pages/News'
import Notifications from '@/pages/Notifications'
import MainContextProvider from './mainContext'
import AppAnalytics from '@/pages/AppAnalytics'
import ToolsenseMachines from '@/pages/ToolsenseMachine'
import ReportsToolsense from '@/pages/ReportsToolsense'

interface Props {
}

const Layout = ({...props}: Props) => {

    const authCtx = useContext(AuthContext);

    const [title, setTitle] = useState<JSX.Element>(<></>)

    if (!authCtx.isLoggedIn) {
        return (

            <Routes>
                <Route path="/Login" element={<LoginPage/>}/>
                <Route path="*" element={< Navigate replace to='/Login'/>}/>
            </Routes>
        );

    } else return (
        <div className={styles.container}>
            <div className={styles.sidebar}>
                <Sidebar/>
            </div>

            <MainContextProvider>
                <main {...props} className={styles.main_container}>
                    <div className={styles.header}>
                        <Header/>
                    </div>
                    <Routes>
                        {/* DASHBOARD */}
                        <Route path='/Dashboard' element={<Dashboard/>}/>

                        {/* AMR */}
                        <Route path='/AmrMachines/:origin' element={<AmrMachines/>}/>
                        <Route path='/Machine/:origin' element={<Machine/>}/>
                        <Route path='/Sites/:origin' element={<Sites/>}/>
                        <Route path='/Site/:origin' element={<Site/>}/>
                        <Route path='/Assists' element={<Assist/>}/>
                        <Route path='/Reports' element={<Reports/>}/>

                        {/* TOOLSENSE */}
                        <Route path='/ToolsenseMachines/:origin' element={<ToolsenseMachines/>}/>
                        <Route path='/Machine/:origin' element={<Machine/>}/>
                        <Route path='/Sites/:origin' element={<Sites/>}/>
                        <Route path='/Site/:origin' element={<Site/>}/>
                        <Route path='/ToolsenseReports' element={<ReportsToolsense/>}/>

                        {/* PROFILE */}
                        <Route path='/Profile' element={<Profile onClick={(title) => {setTitle(title)}}/>}/>
                        
                        {/* NOTIFICATIONS */}
                        <Route path='/Notifications' element={<Notifications/>}/>

                        {/* NEWS */}
                        <Route path='/News' element={<News/>}/>

                        {/* ANALYTICS */}
                        <Route path='/AppAnalytics' element={<AppAnalytics/>}/>

                        {/* WILDCARD */}
                        <Route path="/*" element={<Navigate replace to='/Dashboard'/>}/>

                    </Routes>
                </main>
            </MainContextProvider>
        </div>

    );
}

export default Layout
