import SiteAmr from '@/components/SiteAmr';
import img from '../../assets/images/MapIcon.svg';
import AmrMachinesIcon from '../../assets/images/SiteMachine.svg';
import { useRemoteGet, useWindowContext } from '@/hooks';
import {
	CustomSitesResourceService,
    CustomToolsenseMachinePageResourceService
} from '@/services/openapi';
import machine_active_mobile from '@Images/icon_machine.svg';
import sites_active_mobile from '@Images/Icon_point.svg';
import {
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import styles from './ToolsenseMachines.module.css';
import Label from '@/components/UI/Label';
import { useTranslation } from 'react-i18next';
import FilterBar from '@/components/Filter/Filterbar';
import Button from '@/components/UI/Button';
import CloseIcon from '../../assets/images/closeIcon.svg';
import Table from '@/components/UI/Table/Table';
import MainContext from '@/contexts/MainContext';
import { PagingContextProvider } from '@/contexts/providers/PagingContextProvider';
import ButtonMobile from '@/components/UI/ButtonMobile';
import Sites from '../Sites';
import TableMobile from '@/components/UI/Table/TableMobile';
import { SelectInstance, SingleValue } from 'react-select';
import { SelectDataProps } from '@Ui/Select/SelectBox';
import { ComboSelectProps } from '@/components/UI/Select/ComboSelect/ComboSelect';
import CustomLoader from '@/components/UI/CustomLoader';

const ToolsenseMachines = () => {
	const { size, isMobile } = useWindowContext();
	// const authCtx = useContext(AuthContext);
	const { t } = useTranslation();
	const [showFiltersPage, setShowFiltersPage] = useState<boolean>(false);

	const categoryRef = useRef<SelectInstance<SelectDataProps> | null>(null);
	const [category, setCategory] = useState<string>('All');
	const [categoryIsUserTriggered, setCategoryIsUserTriggered] =
		useState<boolean>(false);

	const siteRef = useRef<SelectInstance<SelectDataProps> | null>(null);
	const [site, setSite] = useState<string>('All');
	const [siteIsUserTriggered, setSiteIsUserTriggered] = useState<boolean>(false);

	const sitesData = useRemoteGet({
		remoteMethod: () => {
			if (category !== 'All') {
				return CustomSitesResourceService.getSitesByDeviceCategory(category);
			} else {
				//if(typeof fundingRequest.data?.id)
				// return CustomSitesResourceService.getAllSites1();
				return CustomSitesResourceService.getAllSitesOfACertainOrigin('Toolsense');
			}
		},
		lazy: false
	});

	const deviceCategoriesData = useRemoteGet({
		remoteMethod: () => {
			return CustomToolsenseMachinePageResourceService.toolsenseMachineCategory(
				'Toolsense',
				site !== 'All' ? parseInt(site) : undefined
			);
		},
		lazy: false
	});

	const toolsenseMachineSitesCount = useRemoteGet({
		remoteMethod: () => {
			//if(typeof fundingRequest.data?.id)
			// return CustomSitesResourceService.getAllSites1();
			return CustomToolsenseMachinePageResourceService.toolsenseMachinePageCountDevice();
		},
		lazy: false
	});

	const allMachines = useMemo(() => {
		let countMachines = 0;
		toolsenseMachineSitesCount.data?.forEach((site) => {
			countMachines = countMachines + (site?.allMachine ?? 0);
		});
		return countMachines;
	}, [toolsenseMachineSitesCount.data]);

	// Update sites filter when category change
	useEffect(() => {
		if (categoryIsUserTriggered) {
			sitesData.fetch();
		}
	}, [category]);

	// Update category filter when site change
	useEffect(() => {
		if (siteIsUserTriggered) {
			deviceCategoriesData.fetch();
		}
	}, [site]);

	useEffect(() => {
		if (siteIsUserTriggered && site === 'All' && category === 'All') {
			setSiteIsUserTriggered(false);
		}
	}, [site, category]);

	const setSitesOptionSelected = () => {
		let data: SelectDataProps = { value: 'All', label: 'All' };
		if (site !== 'All' && sitesData.data) {
			const sites = sitesData.data.find((sites) => sites.id === parseInt(site));
			data = { value: sites?.id?.toString() ?? '', label: sites?.siteName ?? '' };
		}
		return data;
	};
	
	const selectSites: ComboSelectProps = {
		selectData:
			sitesData.data?.map((val) => {
				return { value: '' + val.id ?? '', label: val.siteName ?? '' };
			}) ?? [],
		textLabel: t('AmrMachines.sites'),
		nameSelect: 'selectSites',
		imgSrc: (size.width as number) > 900 ? undefined : sites_active_mobile,
		firstOption: 'All',
		ref: siteRef,
		valueSelected: setSitesOptionSelected(),
		isUserTriggered: siteIsUserTriggered,
		onChange: (newValue, actionMeta, isUserTriggered) => {
			setSiteIsUserTriggered(isUserTriggered);
			setCategoryIsUserTriggered(false);

			const singleValue = newValue as SingleValue<SelectDataProps>;
			if (isUserTriggered && singleValue?.value === 'All') {
				setCategory('All');
			}
			if (singleValue !== null && actionMeta.action === 'select-option') {
				setSite(singleValue.value);
			}
		}
	};

	const selectCategory: ComboSelectProps = {
		selectData:
			deviceCategoriesData.data?.map((val) => {
				return { value: val, label: val };
			}) ?? [],
		textLabel: t('dashboard.category'),
		nameSelect: 'selectCategory',
		imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
		firstOption: 'All',
		ref: categoryRef,
		valueSelected: { value: category, label: category },
		isUserTriggered: categoryIsUserTriggered,
		onChange: (newValue, actionMeta, isUserTriggered) => {
			setCategoryIsUserTriggered(isUserTriggered);
			// setSiteIsUserTriggered(false);

			const singleValue = newValue as SingleValue<SelectDataProps>;
			if (singleValue !== null && actionMeta.action === 'select-option') {
				setCategory(singleValue.value);
			}
		}
	};

	const colonne = {
		seriaN: t('columns.armMachines.seriaN'),
		siteName: t('columns.armMachines.siteName'),
		siteLocation: t('columns.armMachines.siteLocation'),
		model: t('columns.armMachines.model'),
		totalWorkDone: t('machine.toolsense.total_work_done'),
		totalUsagePerDay: t('columns.armMachines.totalUsagePerDay'),
		toolsenseTotRuntime: t('columns.armMachines.totRuntime'),
		toolsenseTotUtilization: t('columns.armMachines.totUtilization')
	};

	const titleCtx = useContext(MainContext);

	const [titleLoaded, setTitleLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (!titleLoaded) {
			if (toolsenseMachineSitesCount.data) {
				let machineCounter = 0;
				toolsenseMachineSitesCount.data.map((val) => {
					if (val.machineNumber) {
						machineCounter += val.machineNumber;
					}
				});
				titleCtx.setTitle(
					<>
						<Label text={machineCounter + ' '} className={styles.titleLabelBlue} />
						<Label text={t('sidebar.toolsense')} className={styles.titleLabel} />
					</>
				);
				setTitleLoaded(true);
			}
		}
	}, [toolsenseMachineSitesCount]);

	const handleResetFilters = () => {
		setCategoryIsUserTriggered(true);
		setCategory('All');
		setSite('All');
	};

	const buttonRef = useRef<HTMLButtonElement>(null);

	const applyFilters = () => {
		if (!toolsenseMachineSitesCount.loading) toolsenseMachineSitesCount.fetch();
	};

	const loader = () => {
		if (toolsenseMachineSitesCount.loading || sitesData.loading || deviceCategoriesData.loading)
			return (
				<span>
					<CustomLoader searchLoader={true} />
				</span>
			);
		else return <span></span>;
	};

	return (
		<>
			<div className={styles.container}>
				<div>
					{isMobile && ( //---------------------------HEADER MOBILE
						<div className={styles.title_container}>
							<Label text={allMachines + ' '} className={styles.title_labelColored} />
							<Label text={t('sidebar.toolsense')} className={''} />
						</div>
					)}
					<div className={styles.subHeader_container}>
						<Label text={t('AmrMachines.in')} className={''} />
						<Label
							text={'' + toolsenseMachineSitesCount.data?.length}
							className={styles.subHeader_labelColored}
						/>
						<Label text={t('AmrMachines.sites')} className={''} />
					</div>
				</div>

				<div className={styles.sites_container}>
					<div className={styles.sitesScroll}>
						{toolsenseMachineSitesCount.data?.map((site, id) => {
							return (
								<SiteAmr
									type={'siteToolsense'}
									id={id}
									titleImg={img}
									titleOverlayImg={AmrMachinesIcon}
									topSmallTitle={t('AmrMachines.site')}
									titleText={site.site ?? ''}
									divider1={true}
									number={site.machineNumber}
									label1={'Tot. Toolsense Machine on site'}
									progressBarColor="#20ACD9"
									progressBarStatus={site.percent}
									label2={'' + site.percent + '% are Toolsense machine'}
									footerText={'' + site.allMachine + ': tot. machine on site'}
								/>
							);
						})}
					</div>
				</div>

				{/*---------------- FILTER MOBILE START -------------------------*/}
				{isMobile && (
					<div className={styles.filterBar_container}>
						<div className={styles.filterBar_header}>
							<div className={styles.filter_mobile}>
								<ButtonMobile
									text={t('assist.filters')}
									onClick={() => {
										setShowFiltersPage(!showFiltersPage);
									}}
									iconStart="filtersIcon.svg"
								/>
								{/* <Button name={t('assist.filters')} className={styles.button_mobile} iconStart={'filterIcon.svg'} onClick={()=>{}}/> */}
							</div>
						</div>

						<div
							className={
								showFiltersPage ? styles.filterBar_body : styles.filterBar_body_hide
							}
						>
							<FilterBar
								filters={[
									{
										type: 'Select',
										selectData: selectCategory,
										imgSrc: machine_active_mobile,
										boxClassName: styles.select_container
									},
									{
										type: 'Select',
										selectData: selectSites,
										imgSrc: sites_active_mobile,
										boxClassName: styles.select_container
									}
								]}
							/>
							<Button
								name={t('AmrMachines.resetFilters')}
								className={styles.resetFilters_btn}
								iconStart={CloseIcon}
								iconStartClassName={styles.resetFiltersIcon}
								onClick={handleResetFilters}
							/>
						</div>

						<div className={styles.searchBtn_container}>
							<Button
								ref={buttonRef}
								name={t('dashboard.applyFilters')}
								className={styles.searchBtn}
								onClick={applyFilters}
							/>
							{loader()}
						</div>
					</div>
				)}
				{/*---------------- FILTER MOBILE END -------------------------*/}

				<div className={styles.table_main_container}>
					{/*---------------- FILTER DESKTOP START -------------------------*/}
					{!isMobile && (
						<div className={styles.filterBar_container}>
							<div className={styles.filterBar_header}>
								<Label text={t('AmrMachines.filters')} className={styles.filterLabel} />
								<Button
									name={t('AmrMachines.resetFilters')}
									className={styles.resetFilters_btn}
									iconStart={CloseIcon}
									iconStartClassName={styles.resetFiltersIcon}
									onClick={handleResetFilters}
								/>
							</div>

							<div className={styles.filterBar_body}>
								<FilterBar
									filters={[
										{
											type: 'Select',
											selectData: selectCategory,
											boxClassName: styles.select_container
										},
										{
											type: 'Select',
											selectData: selectSites,
											boxClassName: styles.select_container
										}
									]}
								/>
							</div>

							<div className={styles.searchBtn_container}>
								<Button
									ref={buttonRef}
									name={t('dashboard.applyFilters')}
									className={styles.searchBtn}
									onClick={applyFilters}
								/>
								{loader()}
							</div>
						</div>
					)}
					{/*---------------- FILTER DESKTOP END -------------------------*/}

					<div className={styles.table_container}>
						<PagingContextProvider
							searchRef={buttonRef}
							queryParams={{ site, category}}
							remoteMethod={(queryParams, paging) => {
								return () => {
									return CustomToolsenseMachinePageResourceService.toolsenseMachinePageTable(
										queryParams.site !== 'All' ? parseInt(queryParams.site!) : undefined,
										queryParams.category !== 'All' ? queryParams.category : undefined,
										paging.pageNumber,
										paging.pageSize,
										paging.sortBy, // TODO: Add sorting order
										paging.sortOrder
									);
								};
							}}
						>
							<>
								{!isMobile && (
									<Table
										columns={colonne}
										type={'amrRanking'}
										color={'blue'}
										paging={true}
									/>
								)}
								{isMobile && (
									<TableMobile
										{...{ columns: colonne, type: 'amrRanking', color: 'blue' }}
									/>
								)}
							</>
						</PagingContextProvider>
					</div>
				</div>
				{isMobile && <Sites />}
			</div>
		</>
	);
};

export default ToolsenseMachines;
