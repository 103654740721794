import {PagingContext} from "@/contexts/paging-context";
import {PageRequestType, PageResponseType} from "@Ui/Table/paging";
import {useRemoteGet} from "@/hooks";
import React, {useCallback, useEffect} from "react";

export type DefaultQueryParams = {
  date1?: string;
  date2?: string;
  type?: string;
  site?: string;
  deviceId?: string;
  deviceModel?: string;
  category?: string;
  rin?: string[];
  model?: string;
  route?: string;
};

type PagingProviderProps<T> = {
  searchRef?: React.RefObject<HTMLButtonElement>;
  children: JSX.Element | JSX.Element[];
  queryParams: DefaultQueryParams;
  remoteMethod: (queryParams: DefaultQueryParams, paging: PageRequestType) => T;
  callbackData?: (data: PageResponseType<any>) => T;
  forceTrigger?: boolean;
  triggerRemoteGet?: boolean;
};

export const PagingContextProvider = ({
                                        searchRef,
                                        children,
                                        queryParams,
                                        remoteMethod,
                                        callbackData,
                                        forceTrigger,
                                        triggerRemoteGet
                                      }: PagingProviderProps<any>) => {
  const [pageNumber, setPageNumber] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [sortBy, setSortBy] = React.useState<string>();
  const [sortOrder, setSortOrder] = React.useState<
      "ASC" | "DESC" | undefined
  >();
  const [totalPages, setTotalPages] = React.useState<number>(1);
  const [totalElements, setTotalElements] = React.useState<number>();
  const [query, setQuery] = React.useState<DefaultQueryParams>();
  const [updateFetch, setUpdateFetch] = React.useState<boolean>(false);

  const remoteGetMethod = remoteMethod(queryParams, {
    pageNumber,
    pageSize,
    sortBy,
    sortOrder,
  });
  const remoteGet = useRemoteGet({
    remoteMethod: remoteGetMethod,
    lazy: true,
  });

  const handleSearch = useCallback(() => {
    setPageNumber(0);
    setUpdateFetch(true);
  }, [])

  useEffect(() => {
    if (typeof searchRef !== 'undefined' && searchRef.current) {
      searchRef.current.addEventListener('click', handleSearch);
    }
    return () => {
      if (typeof searchRef !== 'undefined' && searchRef.current) {
        searchRef.current.removeEventListener('click', handleSearch)
      }
    }
  }, [searchRef]);

  useEffect(() => {
    if (forceTrigger && (typeof totalElements === 'undefined' || triggerRemoteGet)) {
      const notEmpty = Object.keys(queryParams).every(
          (value) => typeof value !== 'undefined'
      );

      if (notEmpty) {
        setQuery(queryParams);
        remoteGet.fetch();
      }
    }

  }, [forceTrigger]);


  useEffect(() => {
    if (typeof query === "undefined") {
      setQuery(queryParams);
      remoteGet.fetch();
    } else {
      const queryIsEqual = Object.keys(queryParams).every(
          (value) =>
              query[value as keyof DefaultQueryParams] ===
              queryParams[value as keyof DefaultQueryParams]
      );
      if (!queryIsEqual || updateFetch) {
        remoteGet.fetch();
        setQuery(queryParams);
        setPageNumber((prevState) => {
          if (!queryIsEqual) {
            return 0;
          }
          return prevState;
        });
        setUpdateFetch(false);
      }
    }
  }, [updateFetch, pageSize, sortBy, sortOrder]);

  useEffect(() => {
    if (typeof remoteGet.data !== "undefined" && remoteGet.data) {
      const data = remoteGet.data as PageResponseType<any>;
      setTotalPages(data.totalPages ? data.totalPages : 1);
      setTotalElements(data.totalElements);
      if (typeof callbackData != "undefined") {
        callbackData(data);
      }
    }
  }, [remoteGet, callbackData]);

  const prevOnClick = useCallback(() => {
    if (typeof pageNumber !== "undefined" && pageNumber > 0) {
      setPageNumber(pageNumber - 1);
      setUpdateFetch(true);
    }
  }, [remoteGet, pageNumber]);

  const nextOnClick = useCallback(() => {
    if (
        typeof pageNumber !== "undefined" &&
        typeof totalPages !== "undefined" &&
        pageNumber < totalPages - 1
    ) {
      setPageNumber(pageNumber + 1);
      setUpdateFetch(true);
    }
  }, [remoteGet, pageNumber, totalPages]);

  const firstOnClick = useCallback(() => {
    setPageNumber(0);
    setUpdateFetch(true);
  }, [remoteGet, pageNumber]);

  const lastOnClick = useCallback(() => {
    if (typeof totalPages !== "undefined") {
      setPageNumber(totalPages - 1);
      setUpdateFetch(true);
    }
  }, [remoteGet, totalPages]);

  return (
      <PagingContext.Provider
          value={{
            pageNumber,
            setPageNumber,
            pageSize,
            setPageSize,
            sortBy,
            setSortBy,
            sortOrder,
            setSortOrder,
            totalPages,
            setTotalPages,
            totalElements,
            setTotalElements,
            prevOnClick,
            nextOnClick,
            firstOnClick,
            lastOnClick,
            remoteGet,
            setUpdateFetch,
          }}
      >
        {children}
      </PagingContext.Provider>
  );
};
