import styles from "./Sidebar.module.css";
import logo from "@Images/logo-tennant.svg";
import Img from "@Ui/Img";
import { NavLink, useLocation } from "react-router-dom";
import dashboard_icon from "@Images/dashboard.svg";
import amrmachines_icon from "@Images/AmrMachines.svg";
import sites_icon from "@Images/sites.svg";
import assist_icon from "@Images/assist.svg";
import reports_icon from "@Images/reports.svg";
import utilitycenter_icon from "@Images/UtilityCenter.svg";
import analytics_icon from "@Images/analytics-icon.svg";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import CustomerSurvey from "@/components/CustomerSurvey";
import AuthContext from "@Components/LoginForm/auth-context";

const Sidebar = () => {
  const { t } = useTranslation();
  const [menuPressed, setMenuPressed] = useState("");
  const location = useLocation();

  const authCtx = useContext(AuthContext);
  const isRoleTennant = authCtx.role?.some((r) => r.name === "ROLE_TENNANT");

  useEffect(() => {
    switch (location.pathname) {
      case "/Dashboard": {
        setMenuPressed("0");
        break;
      }
      case "/AmrMachines/AMR": {
        setMenuPressed("1");
        break;
      }
      case "/Machine/AMR": {
        setMenuPressed("1");
        break;
      }
      case "/Sites/AMR": {
        setMenuPressed("1_0");
        break;
      }
      case "/Site/AMR": {
        setMenuPressed("1_0");
        break;
      }
      case "/Assists": {
        setMenuPressed("1_1");
        break;
      }
      case "/Reports": {
        setMenuPressed("1_2");
        break;
      }
      case "/ToolsenseMachines/Toolsense": {
        setMenuPressed("2");
        break;
      }
      case "/Machine/Toolsense": {
        setMenuPressed("2");
        break;
      }
      case "/Sites/Toolsense": {
        setMenuPressed("2_0");
        break;
      }
      case "/Site/Toolsense": {
        setMenuPressed("2_0");
        break;
      }
      case "/ToolsenseReports": {
        setMenuPressed("2_1");
        break;
      }
      case "/News": {
        setMenuPressed("3");
        break;
      }
      case "/AppAnalytics": {
        setMenuPressed("4");
        break;
      }
      default:
    }
  }, [location]);

  const menuItem = [
    {
      id: 0,
      path: "/Dashboard",
      name: t("sidebar.dashboard"),
      icon: <Img src={dashboard_icon} alt="" className={styles.link_icon} />,
    },
    {
      id: 1,
      path: "/AmrMachines/AMR",
      name: t("sidebar.amrMachines"),
      icon: <Img src={amrmachines_icon} alt="" className={styles.link_icon} />,
      childrens: [
        {
          id: 2,
          path: "/Sites/AMR",
          name: t("sidebar.sites"),
          icon: <Img src={sites_icon} alt="" className={styles.link_icon} />,
        },
        {
          id: 3,
          path: "/Assists",
          name: t("sidebar.assists"),
          icon: <Img src={assist_icon} alt="" className={styles.link_icon} />,
        },
        {
          id: 4,
          path: "/Reports",
          name: t("sidebar.reports"),
          icon: <Img src={reports_icon} alt="" className={styles.link_icon} />,
        },
      ],
    },
    {
      id: 5,
      path: "/ToolsenseMachines/Toolsense",
      name: t("sidebar.toolsense"),
      icon: <Img src={amrmachines_icon} alt="" className={styles.link_icon} />,
      childrens: [
        {
          id: 6,
          path: "/Sites/Toolsense",
          name: t("sidebar.sites"),
          icon: <Img src={sites_icon} alt="" className={styles.link_icon} />,
        },
        {
          id: 7,
          path: "/ToolsenseReports",
          name: t("sidebar.reports"),
          icon: <Img src={reports_icon} alt="" className={styles.link_icon} />,
        },
      ],
    },
    {
      id: 8,
      path: "/News",
      name: t("sidebar.news"),
      icon: (
        <Img src={utilitycenter_icon} alt="" className={styles.link_icon} />
      ),
    },
  ];

  if (isRoleTennant) {
    menuItem.push({
      id: 9,
      path: "/AppAnalytics",
      name: t("sidebar.appAnalytics"),
      icon: <Img src={analytics_icon} alt="" className={styles.link_icon} />,
    });
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.sidebar_container}>
          <NavLink to={"/Dashboard"}>
            <div className={styles.logo_container}>
              <Img src={logo} className={styles.logo} alt="logo" />
            </div>
          </NavLink>

          <div className={styles.navlink_container}>
            {menuItem.map((item, index) => (
              <>
                <NavLink
                  key={index}
                  to={item.path}
                  className={
                    menuPressed === "" + index
                      ? styles.navlinkActive
                      : styles.navlink
                  }
                >
                  <div className={styles.link}>
                    <div
                      className={
                        menuPressed === "" + index
                          ? styles.link_iconActive
                          : styles.link_icon
                      }
                    >
                      {item.icon}
                    </div>
                    <div className={styles.link_text}>{item.name}</div>
                  </div>
                </NavLink>

                {item.childrens && (
                  <div className={styles.subnav_container}>
                    {item.childrens.map((child, index1) => {
                      return (
                        <NavLink
                          key={index + "_" + index1}
                          to={child.path}
                          className={
                            menuPressed === "" + (index + "_" + index1)
                              ? styles.subnavlinkActive
                              : styles.subnavlink
                          }
                          onClick={() => {
                            setMenuPressed("" + (index + "_" + index1));
                          }}
                        >
                          <div className={styles.link}>
                            <div
                              className={
                                menuPressed === "" + (index + "_" + index1)
                                  ? styles.link_iconActive
                                  : styles.link_icon
                              }
                            >
                              {child.icon}
                            </div>
                            <div className={styles.link_text}>{child.name}</div>
                          </div>
                        </NavLink>
                      );
                    })}
                  </div>
                )}
              </>
            ))}
          </div>
          <div className={styles.footer}>
            <CustomerSurvey />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
